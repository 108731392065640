<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch">
                <le-input label="站点名称" v-model="pageParam.params.stationName" />
            </le-search-form>
            <le-pagview ref="observeStationListPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.observeStationList" :pageSizeDefault='10'>
                <el-table ref="observeStationList" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column prop="province" label="站点名称" min-width="140" fixed='left'>
                        <template slot-scope="{ row }">
                            <span>{{ row.name || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stationName" label="历史营业额" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.hisAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="companyName" label="今日营业额" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.todayAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="今日退款" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.todayRefundAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="今日收益" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.todayIncome) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceType" label="今日单插槽收益" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.todayPerSlotIncome) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceType" label="历史单插槽收益" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.hisPerSlotIncome) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="近7日达标" min-width="240">
                        <template slot-scope="{ row }">
                            <div class="a-flex-rfsc a-flex-wrap a-fs-12" >
                                <div class="a-flex-cfsc grade-list-item" :class="ite.grade?'compliance':'uncompliance'" v-for="(ite,idx) in row.gardeList" :key="idx">
                                    <span class="a-fs-12 a-c-white">{{ idx == 0? '今日':ite.day.slice(5,ite.day.length) }}</span>
                                    <span class="a-fs-12 a-c-white">{{ ite.grade?'已达标':'未达标' }}</span>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="近30日达标次数" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.lastThirtyCount || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="达标率" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ (row.gradeRate*100).toFixed(2) || 0 }}%</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="已运行天数" min-width="140">
                        <template slot-scope="{ row }">
                            <span>{{ row.serveDays || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="插槽数(在线/总数/使用中)" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.slotOnlineNum || 0 }}/{{ row.slotNum || 0 }}/{{ row.useNum || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="设备数(在线/总数)" min-width="150">
                        <template slot-scope="{ row }">
                            <span>{{ row.deviceOnlineNum || 0 }}/{{ row.deviceNum || 0 }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" width="100" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="详情" placement="top" v-if="$_has(21)">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="toBusinessAnalysisInfo(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                util: util,
                tableData: [],
                pageParam: {
                    url: this.$Config.apiUrl.getObserveStationPage,
                    method: "post",
                    params: {
                        observeId: this.$route.query.observeId,
                        gradeId: this.$route.query.gradeId,
                        stationName: ''
                    },
                    freshCtrl: 1,
                },
                title: '',//页面头部
                observeId: '',
                gradeId: ''
            }
        },
        created () {
            this.title = this.$route.query.observeName
            this.observeId = this.$route.query.observeId
            this.gradeId = this.$route.query.gradeId
        },
        methods:{
            //获取列表
            setTableData(data) {
                this.tableData = data;
            },
            handlerRest() {
                this.pageParam.params = {
                    stationName: '',
                    observeId: this.observeId,
                    gradeId: this.gradeId,
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['observeStationList'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            editObserve (type) {

            },
            toBusinessAnalysisInfo (datas) {
                this.$router.push({
                    path: '/businessAnalysis/businessAnalysis-info',
                    query: {
                        id: datas.stationId,
                        title: datas.name,
                        type: 'station',
                        stationType: datas.stationType
                    }
                })
            },
            exportfile () {
                // this.$exfile({
                //     code: 15,
                //     fileName: this.pageParam.params.searchStartTime + ' 至 ' + this.pageParam.params.searchEndTime + ' 省份经营分析',
                //     startTime: this.pageParam.params.searchStartTime,
                //     endTime: this.pageParam.params.searchEndTime,
                //     params: this.pageParam.params
                // })
            }
        }
    }
</script>

<style lang="scss" scoped>
.s-btn-add{
    width: 105px;
}
/deep/ .s-search-label{
    width: 100px
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
.grade-list-item{
    width: 50px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    color: #ffffff;
    padding: 4px 0;
}
.grade-list-item:first-child{
    width: 105px;
}
.uncompliance{
    background: #F56C6C;
}
.compliance{
    background: #67C23A;
}
</style>